











































































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'

@Component({ name: 'SettingIndex' })
export default class SettingIndex extends VueBase {
  menuActive = {
    策略管理: true,
    // 组织管理: false,
  }

  menuGroup = {
    策略管理: [
      'strategyManage',
      // 'templateManage',
      'hookRule',
      'sensitiveManage',
      // 'scaManage',
    ],
    // 项目模版: ['projectTemplate'],
    密码修改: ['changePassword'],
    品牌配置: ['changeLogo'],
    // 状态监控: ['StatusMonitoring'],
    // 系统配置: ['SystemSettings'],
    // 操作日志: ['logManage'],
    关于洞态: ['about'],
  }
  private curModule(path: string) {
    return this.$route.name === path
  }

  RouterMap = {}

  get Routers() {
    const R = this.$store.getters.routers[0].children.filter((i: any) => {
      return i.name === 'setting'
    })[0]
    if (R) {
      return R.children
    } else {
      return []
    }
  }
  created() {
    this.Routers.forEach((route: any) => {
      this.RouterMap[route.name] = route
    })
  }
  get userInfo(): { username: string } {
    return this.$store.getters.userInfo
  }
}
